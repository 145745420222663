


.main{
height: 950px;
display: flex;
justify-content: center;
align-items: center;
min-height: 100vh;
background: #fff;
}

@media (max-width: 768px){
    .main{
        height: 600px;
    }
}

@media (max-width: 480px){
    .main{
        height: 1300px;
    }
}


.card{
position: relative;
width: 800px;
height: 400px;
display: flex;
align-items: center;
border-radius: 20px;
transition: 0.5s;
}

.card .circle{
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;

border-radius: 20px;
overflow: hidden;
}

.card .circle::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    clip-path: circle(120px at center);
    transition: 0.5s;
}

.card:hover .circle:before{
    background: #0065c3;
    clip-path: circle(700px at center);
}

.card img{
    position: absolute;
    top: 50%;
    left: 50%;
    height: 300px;
    transform: translate(-50%,-50%);
    pointer-events: none;
    transition: 0.5s;
}

.card:hover img{
    left: 78%;
    height: 300px;

}

.card .content{
    position: relative;
    width: 50%;
    padding: 20px 20px 20px 40px;
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;
}

.card:hover .content{
    left: 0;
    opacity: 1;
    visibility: visible;

}

.card .content h2{
    margin-bottom: 40px;
    color:black;
    text-transform: uppercase;
    font-size: 2em;
    line-height: 1em;
}

.card .content p{
    margin-top: 40px;
    margin-bottom: 40px;
    color: black;
}

@media (max-width: 768px){
    
    .card{
        width: auto;
        max-width: 350px;
        align-items: flex-start;
        margin: 10px;
    }

    .card:hover{
        height: 600px;
    }

    .card .content{
        width: 100%;
        left: 0;
        padding: 30px;
    }

    .card:hover img{
        margin-top: 20px;
        top: 70%;
        left: 50%;
        height: 300px;
    }
}

@media (max-width: 480px){
    .card .content{
        padding: 20px;
    }
}